
export default {
  name: 'OneThirdVisual',
  props: {
    src: {
      type: String,
      default: '',
    },
  },
}
