
import OneThirdVisual from '@/components/OneThirdVisual.vue'
import OneThirdCardImage from '@/components/OneThirdCardImage.vue'
import { createSEOMeta } from '@/utils/seo'

export default {
  name: 'IndexPage',
  components: { OneThirdVisual, OneThirdCardImage },
  head() {
    return {
      title: 'XAI-Studio - Das Innovationslabor für Erklärbare KI',
      meta: createSEOMeta({
        title: 'XAI-Studio - Das Innovationslabor für Erklärbare KI',
        description:
          'Das XAI-Studio ist das Innovationslabor für Erklärbare KI in Unternehmen und dem Öffentlichen Sektor.',
      }),
    }
  },
}
